<template>
  <div>
    <el-table highlight-current-row  ref="multiTable" @cell-click="cellClick" :data="psotForm.psot_prod_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="产品编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.prod_no'">
            <el-input :disabled="true" v-model="psotForm.psot_prod_list[scope.$index].prod_no" maxlength="10" show-word-limit placeholder="暂无物料编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.prod_name'">
            <el-input :disabled="true" v-model="psotForm.psot_prod_list[scope.$index].prod_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.prod_spec'">
            <el-input disabled v-model="psotForm.psot_prod_list[scope.$index].prod_spec" maxlength="30" show-word-limit placeholder="暂无产品规格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品类型">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.prod_type'">
            <el-select disabled v-model="psotForm.psot_prod_list[scope.$index].prod_type" placeholder="暂无产品类型" clearable>
              <el-option
                  v-for="item in prodTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">出库数量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.psot_prod_num'" :rules="[{required:true}]">
            <el-input  show-word-limit maxlength="13" v-model="psotForm.psot_prod_list[scope.$index].psot_prod_num" placeholder="暂无出库数量"></el-input> 
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">库位</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.pstk_bin'" :rules="[{required:true}]">
            <el-select v-model="psotForm.psot_prod_list[scope.$index].pstk_bin" placeholder="暂无产品类型" clearable>
              <el-option
                  v-for="item in prodLocation"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="待出库数量">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'psot_prod_list.'+scope.$index+'.stay_ppot_num'" >
            <el-input  show-word-limit disabled v-model="psotForm.psot_prod_list[scope.$index].stay_ppot_num" placeholder="暂无出库数量"></el-input> 
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {optnAPI} from "@api/modules/optn";

export default {
  name:"ProdAddProd",
  props:{
    psotForm:{
      type: Object,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      // tableHeight:269,
      prodTypeGroupOpt:[],
      prodLocation:[],
      formatNumber:null
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      this.getSmplType()
      this.getLocation()
    },
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.prodTypeGroupOpt = res.data.data;
          console.log()
        }
      })
    },
    // 获取库位
    getLocation(){
      get(optnAPI.getAllContent,{perm_id : 10006})
      .then(res=>{
        if(res.data.code === 0) {
          this.prodLocation = res.data.data;
        }
      })
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      this.multiSelection = null
    },
    //产品信息选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
      this.$emit("handleSelectionChange",this.selectionsList)
    },
    // 单选信息格的时候
    cellClick(val){
      this.$emit("cellClick",val)
    },  
    formatNum(scope){
      console.log(scope)
    },
  },
}
</script>

<style scoped>

</style>>
